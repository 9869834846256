import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { getBackgroundColor } from "../../../../../../libs/utils/acmg";
import ACMGChip from "../../../../../common/chips/ACMGChip";
import { useAppSelector } from "../../../../../../store/hooks";
import {
  CuratedDataObject,
  CuratedDataObjectKeys,
} from "../../../../../../network/reporter/types";
import { discreteArticleDataLabelMap } from "../../../../constants";

type EvidenceViewerArticleCardFooterProps = {
  acmgRecords: string[];
  context?: string[];
  data?: Partial<CuratedDataObject>[];
};

const EvidenceViewerArticleCardFooter = ({
  acmgRecords,
  context,
  data,
}: EvidenceViewerArticleCardFooterProps) => {
  const { acmgClasses } = useAppSelector((state) => state.acmgClasses);

  return (
    <Stack gap={1} mt={0.5}>
      {/* ACMG Call chips section */}
      {acmgClasses && acmgRecords.length > 0 ? (
        <Stack gap={0.25}>
          <Typography variant="text11" fontWeight="bold">
            Contributes to:
          </Typography>
          <Stack direction="row" gap={0.25}>
            {acmgRecords.map((record) => {
              const bgcolor = getBackgroundColor(
                acmgClasses[record.toUpperCase()]
              );
              return (
                <ACMGChip bgcolor={bgcolor} category={record} key={record} />
              );
            })}
          </Stack>
        </Stack>
      ) : null}

      {/* discrete data section */}
      {data && data.length > 0 ? (
        <>
          {data.map((obj, index) => (
            <Box key={`article-data-item-${index}`}>
              {Object.entries(obj).map(([key, value]) => (
                <Typography key={key} component="p" variant="text12">
                  {discreteArticleDataLabelMap[key as CuratedDataObjectKeys]}:{" "}
                  {value}
                </Typography>
              ))}
            </Box>
          ))}
        </>
      ) : null}

      {/* additional context section */}
      {context && context.length > 0 ? (
        <Typography variant="text12">
          Additional Context: {context.map((sentence) => `${sentence}; `)}
        </Typography>
      ) : null}
    </Stack>
  );
};

export default EvidenceViewerArticleCardFooter;
