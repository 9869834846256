import React from "react";
import {
  IconBenign,
  IconPathogenic,
  IconVus,
} from "../common/icons/VariantAssessments";
import {
  IconComputation,
  IconDoctor,
  IconGene,
  IconPopulation,
  IconUnclassified,
  IconVial,
} from "../common/icons/VariantKpis";
import {
  ACMGAndUnclassifiedCategories,
  ACMGCategories,
  UnclassifiedCategories,
} from "../../libs/types/variant";
import { primaryPalette } from "../../libs/styles/theme";
import { CuratedDataObjectKeys } from "../../network/reporter/types";

export const pathogenictyIconMap: {
  benign: React.JSX.Element;
  pathogenic: React.JSX.Element;
  likely_pathogenic: React.JSX.Element;
  likely_benign: React.JSX.Element;
  conflict: React.JSX.Element;
  vus: React.JSX.Element;
  [key: string]: React.JSX.Element; // Index signature
} = {
  benign: <IconBenign />,
  likely_benign: <IconBenign />,
  pathogenic: <IconPathogenic />,
  likely_pathogenic: <IconPathogenic />,
  vus: <IconVus />,
  conflict: <IconVus />,
};

const acmgIconStyles = {
  width: "14px",
  height: "14px",
  color: primaryPalette.grey.neutral_grey_g7,
  marginRight: "4px",
};

export const acmgCategoryIconMap: {
  [key in ACMGAndUnclassifiedCategories]: React.JSX.Element;
} = {
  [ACMGCategories.FUNCTIONAL]: <IconVial sx={acmgIconStyles} />,
  [ACMGCategories.INTRINSIC]: <IconGene sx={acmgIconStyles} />,
  [ACMGCategories.COMPUTATIONAL]: <IconComputation sx={acmgIconStyles} />,
  [ACMGCategories.CLINICAL]: <IconDoctor sx={acmgIconStyles} />,
  [ACMGCategories.POPULATION]: <IconPopulation sx={acmgIconStyles} />,
  [UnclassifiedCategories.UNCLASSIFIED]: (
    <IconUnclassified sx={acmgIconStyles} />
  ),
};

export const discreteArticleDataLabelMap: Record<
  CuratedDataObjectKeys,
  string
> = {
  in_trans: "Confirmed in trans",
  na_type: "N/A Type",
  probands: "Probands",
  segregation: "Segregation",
  strength: "Strength",
  unrelated_proband: "Unrelated Proband(s)",
  zygosity: "Zygosity",
};
