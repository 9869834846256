import { VariantSubTypes, VariantTypes } from "../libs/types/variant";
import { AcmgCriteria, Domain, ProteinShift } from "../libs/types/gene";
import { api } from "./api";

export type GeneResponse = {
  acmg_criteria: {
    data: AcmgCriteria;
    clingen_vcep: boolean;
  };
  domains: Domain[];
  protein_shifts: ProteinShift[];
  protein_sequence: string;
  gene_symbol: string;
  gene_description?: string;
  gene_synonyms: Array<string>;
  gene_type?: string;
  map_location?: string[];
  summary: string;
  transcript: string;
  genomenon_variant_type_count: VariantCounts;
  clinvar_variant_type_count: VariantCounts;
};

export type GeneEvidenceAPIResponse = {
  clinical: Array<string>;
  functional: Array<string>;
};

export type GeneEvidenceResponse = {
  clinical: Set<string>;
  functional: Set<string>;
};

export type VariantCounts = {
  total: number;
  types: {
    [key in VariantTypes]: {
      total: number;
      calls: {
        [key in VariantSubTypes]: number;
      };
    };
  };
};

export const initialACMGCriteria: AcmgCriteria = {
  pvs1: [],
  pp2: [],
  bp1: [],
};

export const initialVariantTypeCount = {
  total: 0,
  types: {
    [VariantTypes.INFRAME]: {
      total: 0,
      calls: {
        [VariantSubTypes.PATHOGENIC]: 0,
        [VariantSubTypes.BENIGN]: 0,
        [VariantSubTypes.VUS]: 0,
        [VariantSubTypes.UNCURATED]: 0,
      },
    },
    [VariantTypes.NONCODING]: {
      total: 0,
      calls: {
        [VariantSubTypes.PATHOGENIC]: 0,
        [VariantSubTypes.BENIGN]: 0,
        [VariantSubTypes.VUS]: 0,
        [VariantSubTypes.UNCURATED]: 0,
      },
    },
    [VariantTypes.MISSENSE]: {
      total: 0,
      calls: {
        [VariantSubTypes.PATHOGENIC]: 0,
        [VariantSubTypes.BENIGN]: 0,
        [VariantSubTypes.VUS]: 0,
        [VariantSubTypes.UNCURATED]: 0,
      },
    },
    [VariantTypes.TRUNCATING]: {
      total: 0,
      calls: {
        [VariantSubTypes.PATHOGENIC]: 0,
        [VariantSubTypes.BENIGN]: 0,
        [VariantSubTypes.VUS]: 0,
        [VariantSubTypes.UNCURATED]: 0,
      },
    },
    [VariantTypes.SPLICE]: {
      total: 0,
      calls: {
        [VariantSubTypes.PATHOGENIC]: 0,
        [VariantSubTypes.BENIGN]: 0,
        [VariantSubTypes.VUS]: 0,
        [VariantSubTypes.UNCURATED]: 0,
      },
    },
    [VariantTypes.SYNONYMOUS]: {
      total: 0,
      calls: {
        [VariantSubTypes.PATHOGENIC]: 0,
        [VariantSubTypes.BENIGN]: 0,
        [VariantSubTypes.VUS]: 0,
        [VariantSubTypes.UNCURATED]: 0,
      },
    },
  },
};

export type GetGeneDataRequest = {
  gene: string;
  source?: string | undefined;
};

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGeneInfo: builder.query<GeneResponse, GetGeneDataRequest>({
      query: (req) => ({
        url: `/gene_page_data?gene=${req.gene}&source=${req.source ?? "gene"}`,
        method: "GET",
      }),
    }),
    getGeneEvidence: builder.query<GeneEvidenceResponse, string[]>({
      async queryFn(genes, _queryApi, _extraOptions, fetchWithBaseUrl) {
        const finalResponse: GeneEvidenceResponse = {
          clinical: new Set(),
          functional: new Set(),
        };

        if (genes.length > 0) {
          for (let i = 0; i < genes.length; i++) {
            const geneEvidenceResponse = await fetchWithBaseUrl({
              url: `/gene_evidence/${genes[i]}`,
              method: "GET",
            });
            if (geneEvidenceResponse.error) {
              return { error: geneEvidenceResponse.error };
            }
            const geneEvidence =
              geneEvidenceResponse.data as GeneEvidenceAPIResponse;
            geneEvidence.clinical.map((item) =>
              finalResponse.clinical.add(item)
            );
            geneEvidence.functional.map((item) =>
              finalResponse.functional.add(item)
            );
          }
        }
        return { data: finalResponse };
      },
    }),
  }),
});

export const {
  useGetGeneInfoQuery,
  useLazyGetGeneInfoQuery,
  useGetGeneEvidenceQuery,
  useLazyGetGeneEvidenceQuery,
} = extendedApi;
