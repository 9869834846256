import {
  BooleanSearch,
  SearchBarTerms,
  SearchedTermIds,
  initialSearchState,
} from "../store/slices/searchSlice";
import { api } from "./api";

export type Article = {
  pmid: string;
  title: string;
  journal: string;
  publication_date: string;
  authors: string[];
  diseases: ArticleDiseases[];
  abstract?: ArticleAbstract[];
  gene_summaries?: ArticleGeneSummary;
  mutation_counts: ArticleMutationCount;
  mesh_headings?: string[];
  keywords?: string[];
  substances?: string[];
  gene_mention_count: number;
  mutation_mention_count: number;
  matched_dna?: boolean;
  matched_supplemental?: boolean;
};

export type ArticleCardBodyData = {
  pmid: string;
  title: string;
  journal: string;
  publication_date: string;
  authors: string[];
};

export type MatchSummary = {
  found_in: string;
  gene_id: string;
  match_count: number;
  match_limit: number;
  synonyms: string[];
  type: "gene" | "variant";
  supplemental_matches?: SupplementalMatchType[];
  mutation_id?: string;
};

export type SupplementalMatchType = {
  converted_file: string;
  count: number;
  match_text: string;
  supplementary_match_source: SupplementaryMatchSource;
};

export type SupplementaryMatchSource = {
  download_link_text: string;
  download_url: string;
  file_name: string;
  provenance: string;
};

export type ArticleGeneSummary = {
  [key: string]: {
    count: number;
    mutation_count: number;
    mutation_mention_count: number;
  };
};

export type ArticleMutationCount = {
  [mutation: string]: number;
};

export type GetArticlesResponse = {
  total: number;
  articles: Article[];
  suppCount?: number;
  ExactDNAMatchCount?: number;
  hasNext: boolean;
};

export const emptyArticlesResponse: GetArticlesResponse = {
  total: 0,
  articles: [],
  hasNext: false,
};

type ArticleAbstract = {
  label: string;
  text: string;
};

export type ArticleSentence = {
  synonym: string;
  gene_id: string;
  type: string;
  found_in: string;
  content: string;
};

export type CondensedContentMatches = {
  type: string;
  entity: string;
  matched: string;
};

export enum HighlightTypes {
  Gene = "gene",
  Variant = "variant",
  Phenotype = "hpo",
  Therapy = "unii",
  Keyword = "keyword",
}

export type CondensedArticleSentence = {
  content: string;
  contentMatches: CondensedContentMatches[];
};

type ArticleDiseases = {
  key: string;
};

type GetArticlesReqBody = {
  urlTermIds: SearchedTermIds;
  urlBooleanParams: BooleanSearch;
  urlCats: string[];
  urlSigTerms: string[];
  options?: GetArticleOptions;
};

type GetArticleOptions = {
  sortField: string;
  sortAsc: boolean;
  searchText?: string;
  filterText?: string;
  highlight?: boolean;
  offset?: number;
  all?: boolean;
};

type GetArticleReqBody = {
  pmid: string;
  urlTermIds: SearchedTermIds;
  urlBooleanParams: BooleanSearch;
  urlCats: string[];
  urlSigTerms: string[];
  options?: GetArticleOptions;
};

type GetArticleCountReqBody = {
  urlTermIds: SearchedTermIds;
  urlBooleanParams: BooleanSearch;
  urlCats: string[];
  urlSigTerms: string[];
};

export type GetArticleResponse = {
  article: Article;
  matchSummaries?: MatchSummary[];
  sentences: ArticleSentence[];
  condensedSentences: CondensedArticleSentence[];
  dbsnpCDNALookup: { [key: string]: string[] };
};

export type GetClinVarArticlesResponse = {
  pmids: {
    [key: string]: number;
  };
};

export interface GetArticleCountResponse {
  total: number;
}

export type GetArticlesRequestPayload = {
  article_list_filter: string;
  article_sort: string;
  article_sort_asc: boolean;
  boolean: boolean;
  cats: Array<string>;
  cnv: Array<string>;
  cnv_match_op: string;
  cnv_op: string;
  disease: Array<string>;
  disease_op: string;
  gene: Array<string>;
  gene_op: string;
  highlight?: boolean;
  hpo: Array<string>;
  hpo_op: string;
  keyword: Array<string>;
  keyword_op: string;
  mutation: Array<string>;
  mutation_op: string;
  mutation_source: string;
  offset: number;
  unii: Array<string>;
  unii_op: string;
  [key: string]: string | string[] | number | boolean | undefined;
};

export const getRequestPayloadJson = (
  urlSearchParams: SearchedTermIds,
  urlBooleanParams: BooleanSearch,
  urlCats: string[],
  urlSigTerms: string[],
  options?: GetArticleOptions
) => {
  return JSON.stringify(
    getRequestPayload(
      urlSearchParams,
      urlBooleanParams,
      urlCats,
      urlSigTerms,
      options
    )
  );
};

export const getRequestPayload = (
  urlSearchParams: SearchedTermIds,
  urlBooleanParams: BooleanSearch,
  urlCats: string[],
  urlSigTerms: string[],
  options?: GetArticleOptions
) => {
  return {
    article_list_filter: options?.searchText ?? "",
    article_sort: options?.sortField ?? "relevance",
    article_sort_asc: options?.sortAsc ?? false,
    boolean: true,
    cats: urlCats,
    cnv: urlSearchParams[SearchBarTerms.cnv],
    cnv_match_op: "intersects",
    cnv_op:
      urlBooleanParams[SearchBarTerms.cnv] ??
      initialSearchState.booleans[SearchBarTerms.cnv],
    disease: urlSearchParams[SearchBarTerms.disease],
    disease_op:
      urlBooleanParams[SearchBarTerms.disease] ??
      initialSearchState.booleans[SearchBarTerms.disease],
    gene: urlSearchParams[SearchBarTerms.gene],
    gene_op:
      urlBooleanParams[SearchBarTerms.gene] ??
      initialSearchState.booleans[SearchBarTerms.gene],
    highlight: !!options?.highlight,
    hpo: urlSearchParams[SearchBarTerms.hpo],
    hpo_op:
      urlBooleanParams[SearchBarTerms.hpo] ??
      initialSearchState.booleans[SearchBarTerms.hpo],
    keyword: urlSearchParams[SearchBarTerms.keyword],
    keyword_op:
      urlBooleanParams[SearchBarTerms.keyword] ??
      initialSearchState.booleans[SearchBarTerms.keyword],
    mutation: urlSearchParams[SearchBarTerms.variant],
    mutation_op:
      urlBooleanParams[SearchBarTerms.variant] ??
      initialSearchState.booleans[SearchBarTerms.variant],
    mutation_source: options?.filterText ?? "supplemental",
    offset: options?.offset ?? 0,
    sig_terms: urlSigTerms,
    unii: urlSearchParams[SearchBarTerms.unii],
    unii_op:
      urlBooleanParams[SearchBarTerms.unii] ??
      initialSearchState.booleans[SearchBarTerms.unii],
  } as GetArticlesRequestPayload;
};

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getArticlesList: builder.query<GetArticlesResponse, GetArticlesReqBody>({
      query: (req) => ({
        url: "/association/articles",
        method: "POST",
        body: getRequestPayloadJson(
          req.urlTermIds,
          req.urlBooleanParams,
          req.urlCats,
          req.urlSigTerms,
          req.options
        ),
      }),
      // This is used because we only want a single cache entry per search query (excluding pmid) for infinite loading
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const ops = { ...queryArgs.options };
        delete ops.offset;
        return {
          endpoint: endpointName,
          options: ops,
          urlTermIds: queryArgs.urlTermIds,
          urlBooleanParams: queryArgs.urlBooleanParams,
          urlCats: queryArgs.urlCats,
          urlSigTerms: queryArgs.urlSigTerms,
        };
      },
      merge: (currentCache, newItems) => {
        currentCache.articles.push(...newItems.articles);
      },
      // Because we have a single cache entry, we need to force a refetch when offset changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.options?.offset !== previousArg?.options?.offset;
      },
    }),
    getFeaturedArticle: builder.query<GetArticleResponse, GetArticleReqBody>({
      query: (req) => ({
        url: `/article/${req.pmid}`,
        method: "POST",
        body: getRequestPayloadJson(
          req.urlTermIds,
          req.urlBooleanParams,
          req.urlCats,
          req.urlSigTerms,
          req.options
        ),
      }),
    }),
    getArticleCount: builder.query<
      GetArticleCountResponse,
      GetArticleCountReqBody
    >({
      query: (req) => ({
        url: "/association/articles/count",
        method: "POST",
        body: getRequestPayloadJson(
          req.urlTermIds,
          req.urlBooleanParams,
          req.urlCats,
          req.urlSigTerms
        ),
      }),
    }),
    getClinVarArticles: builder.query<GetClinVarArticlesResponse, string[]>({
      query: (req) => ({
        url: "association/articles/clinvar",
        method: "POST",
        body: { variants: req },
      }),
    }),
  }),
});

export const {
  useLazyGetArticlesListQuery,
  useLazyGetFeaturedArticleQuery,
  useLazyGetClinVarArticlesQuery,
  useGetArticleCountQuery,
} = extendedApi;
