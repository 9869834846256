import React from "react";
import { useHandleError } from "../../../libs/hooks/errorHandlerHook";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectCuratedVariantData } from "../../../store/selectors/curatedEvidenceSelectors";
import { useLazyGetEvidenceViewerRecordsQuery } from "../../../network/reporter/reporter";
import { GetEvidenceViewerRecordsResponse } from "../../../network/reporter/types";
import EvidenceHeader from "./EvidenceHeader";
import EvidenceViewerArticleList from "./EvidenceViewerArticleList";
import EvidenceViewer from "./EvidenceViewer";
import { updateReporterEvidenceViewerData } from "../../../store/slices/curatedEvidenceSlice";
import EvidenceViewerACMGCriteria from "./EvidenceViewerACMGCriteria/EvidenceViewerACMGCriteria";

type EvidenceViewerContainerProps = {
  isError: boolean;
  onArticleChange: (pmid: string) => void;
};

const EvidenceViewerContainer = ({
  isError,
  onArticleChange,
}: EvidenceViewerContainerProps) => {
  const handleError = useHandleError();
  const dispatch = useAppDispatch();
  const { selectedVariant } = useAppSelector((state) => state.curatedEvidence);
  const curatedVariantData = useAppSelector((state) =>
    selectCuratedVariantData(state)
  );

  const [
    triggerEvidenceViewerData,
    {
      data: evidenceViewerData,
      isUninitialized,
      isLoading,
      isError: isEvidenceViewerError,
    },
  ] = useLazyGetEvidenceViewerRecordsQuery();

  const isEvidenceViewerDataLoading = isUninitialized || isLoading;

  React.useEffect(() => {
    if (selectedVariant && selectedVariant.gene) {
      void triggerEvidenceViewerData(selectedVariant, true)
        .then(({ data }) => {
          dispatch(
            updateReporterEvidenceViewerData(
              data as GetEvidenceViewerRecordsResponse
            )
          );
        })
        .catch((err) => handleError(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant, triggerEvidenceViewerData]);

  return (
    <EvidenceViewer
      isError={isError || isEvidenceViewerError}
      isLoading={isEvidenceViewerDataLoading}
    >
      <EvidenceHeader
        selectedVariant={selectedVariant}
        curatedVariantData={curatedVariantData}
        evidenceViewerData={evidenceViewerData}
      />
      <EvidenceViewerACMGCriteria />
      <EvidenceViewerArticleList onArticleChange={onArticleChange} />
    </EvidenceViewer>
  );
};

export default EvidenceViewerContainer;
