import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import {
  GetArticleResponse,
  GetArticlesResponse,
  GetClinVarArticlesResponse,
  useLazyGetArticlesListQuery,
  useLazyGetClinVarArticlesQuery,
  useLazyGetFeaturedArticleQuery,
} from "../../network/articles";
import { useHandleError } from "../../libs/hooks/errorHandlerHook";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { checkUrlHasTag } from "../../libs/utils/search";
import { UrlParams } from "../../libs/types/url-params";
import { useLazyGetGeneEvidenceQuery } from "../../network/genes";
import { GetReporterCuratedData } from "../../network/reporter/types";
import { updateReporterCuratedData } from "../../store/slices/curatedEvidenceSlice";
import { updateFeaturedArticleData } from "../../store/slices/featuredArticleSlice";
import {
  updateArticleListData,
  updateClinVarArticlesData,
} from "../../store/slices/articleSlice";
import { useSnackbar } from "notistack";
import { SearchBarTerms } from "../../store/slices/searchSlice";
import _isEqual from "lodash/isEqual";
import { selectUrlTermIds } from "../../store/selectors/urlSelectors";
import { getFeatureFlagByName } from "../../store/selectors/featureFlagSelectors";
import { RootState } from "../../store/store";
import { useUrlSearchParamState } from "../../libs/hooks/useSearchParamState";
import { ArticlePageViews } from "../../libs/types/articles";
import { useLazyGetReporterCuratedDataQuery } from "../../network/reporter/reporter";
import ArticlePage from "./article-page";
import ArticleListHeader from "./article-list/ArticleListHeader";
import GeneVariantCard from "./article-gene-variant-card/GeneVariantCard";
import ExactMatch from "./exact-match";
import ArticleList from "./article-list";
import ArticleViewer from "./article-viewer/ArticleViewer";
import TreatmentTrialContainer from "./treatment-trial/TreatmentTrialContainer";
import RelatedVariantsContainer from "./related-variants/RelatedVariantsContainer";
import EvidenceViewerContainer from "./evidence-viewer";
import { isEmpty } from "lodash";

const notValidPmidError =
  "The displayed article has no data matching your search.";

const ArticlePageContainer = () => {
  const [searchParams] = useSearchParams();
  const handleError = useHandleError();
  const { enqueueSnackbar } = useSnackbar();
  const { updateSearchParams } = useUrlSearchParamState();

  const dispatch = useAppDispatch();
  const { isLoggedIn, user } = useAppSelector(
    (state) => state.user,
    shallowEqual
  );
  const { articleSearchFilter, articleFilterItem, articleSortItem } =
    useAppSelector((state) => state.articles, shallowEqual);
  const { urlTermBooleans, urlCats, urlSigTerms, urlTermSuggestions } =
    useAppSelector((state) => state.url);
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const curatedEvidenceIsEnabled = useSelector(
    (state: RootState) =>
      getFeatureFlagByName(state, "curated_content").treatment
  );

  const prevArticleSearchFilterRef = useRef(articleSearchFilter);
  const prevUrlTermIdsRef = useRef(urlTermIds);
  const prevUrlTermBooleansRef = useRef(urlTermBooleans);
  const prevUrlCatsRef = useRef(urlCats);
  const prevUrlSigTermsRef = useRef(urlSigTerms);

  const [offset, setOffset] = useState(0);
  const [isFetchingListAndArticleData, setIsFetchingListAndArticleData] =
    useState(true);

  const articleHeaderRef = useRef<HTMLDivElement | null>(null);
  const articleHeaderHeight =
    articleHeaderRef?.current?.getBoundingClientRect().height ?? 0;

  const [
    triggerCuratedData,
    { data: curatedEvidenceData, isError: isErrorCuratedData },
  ] = useLazyGetReporterCuratedDataQuery();

  useEffect(() => {
    if (!isEmpty(urlTermIds.variant)) {
      void triggerCuratedData(
        {
          urlTermIds: urlTermIds,
          urlString: urlTermIds.toString(),
        },
        true
      )
        .then(({ data }) => {
          dispatch(updateReporterCuratedData(data as GetReporterCuratedData));
        })
        .catch((err) => handleError(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTermIds]);

  const [
    triggerGetArticlesListQuery,
    {
      data: articlesListData,
      isError: isErrorArticlesList,
      isFetching: isFetchingArticlesList,
      isUninitialized: isUninitializedArticlesList,
    },
  ] = useLazyGetArticlesListQuery();

  const isLoadingOrInitArticleList =
    isFetchingArticlesList || isUninitializedArticlesList;

  const isEmptyArticlesList = useMemo(() => {
    return (articlesListData?.articles ?? []).length === 0;
  }, [articlesListData?.articles]);

  const checkHaveParamsUpdated = () => {
    const urlTermIdsUpdated = !_isEqual(prevUrlTermIdsRef.current, urlTermIds);
    const urlTermBooleansUpdated = !_isEqual(
      prevUrlTermBooleansRef.current,
      urlTermBooleans
    );
    const urlTermCatsUpdated = !_isEqual(prevUrlCatsRef.current, urlCats);
    const urlTermSigTermsUpdated = !_isEqual(
      prevUrlSigTermsRef.current,
      urlSigTerms
    );

    return (
      urlTermIdsUpdated ||
      urlTermBooleansUpdated ||
      urlTermCatsUpdated ||
      urlTermSigTermsUpdated
    );
  };

  const fetchArticlesList = async (
    offsetArg: number,
    fetchArticle: boolean
  ) => {
    if (!checkUrlHasTag(urlTermIds)) return;

    setIsFetchingListAndArticleData(true);
    await triggerGetArticlesListQuery(
      {
        urlTermIds: urlTermIds,
        urlBooleanParams: urlTermBooleans,
        urlCats: urlCats,
        urlSigTerms: urlSigTerms,
        options: {
          sortField: articleSortItem.id,
          sortAsc: articleSortItem.asc || false,
          searchText: articleSearchFilter,
          filterText: articleFilterItem.id,
          offset: offsetArg,
        },
      },
      true
    )
      .then(async ({ data }) => {
        dispatch(updateArticleListData(data as GetArticlesResponse));

        // update previous refs so we can compare
        // search params accurately in the useEffect
        prevUrlTermIdsRef.current = urlTermIds;
        prevUrlTermBooleansRef.current = urlTermBooleans;
        prevUrlCatsRef.current = urlCats;
        prevUrlSigTermsRef.current = urlSigTerms;

        const articleSearchFilterHasUpdated =
          articleSearchFilter !== prevArticleSearchFilterRef.current;

        prevArticleSearchFilterRef.current = articleSearchFilter;

        const firstArticlePMID = data?.articles?.[0]?.pmid;
        const pmidSearchParam = searchParams.get(UrlParams.PMID);

        // if articleSearchFilter has not updated and there is an existing PMID search param which is present in the article list, feature the existing PMID article
        // if articleSearchFilter has updated or no PMID search param exists, feature the first article in the results and sync url search params
        const useCurrentFeaturedArticle =
          !articleSearchFilterHasUpdated && pmidSearchParam;
        const pmidToLoad = useCurrentFeaturedArticle
          ? pmidSearchParam
          : firstArticlePMID
          ? firstArticlePMID
          : "";

        if (!pmidSearchParam && pmidToLoad) {
          // sync search params with current featured article pmid
          updateSearchParams({ [UrlParams.PMID]: pmidToLoad }, true);
        }

        const currOffset = Math.floor((data?.articles ?? []).length / 200);
        setOffset(currOffset);

        if ((data?.articles ?? []).length > 0 && pmidToLoad && fetchArticle) {
          await fetchFeaturedArticle(pmidToLoad);
        } else {
          setIsFetchingListAndArticleData(false);
        }
      })
      .catch((err) => handleError(err));
  };

  const handleLoadMore = () => {
    if (articlesListData?.hasNext) {
      void fetchArticlesList(offset, false);
    }
  };

  const [
    triggerGetFeaturedArticleQuery,
    {
      data: featuredArticleData,
      isFetching: isFetchingFeaturedArticle,
      isError: isErrorFeaturedArticle,
    },
  ] = useLazyGetFeaturedArticleQuery();

  const isErrorState =
    isErrorArticlesList ||
    isErrorFeaturedArticle ||
    (curatedEvidenceIsEnabled && isErrorCuratedData);

  const isLoadingArticleViewer =
    !isErrorState &&
    (isFetchingFeaturedArticle || isFetchingListAndArticleData);

  const isEmptyArticleViewer = isEmptyArticlesList || !featuredArticleData;
  const allowArticlesCSVExport = !isEmptyArticlesList && user.is_pro;

  const showCuratedEvidence = useMemo(() => {
    if (
      curatedEvidenceIsEnabled &&
      !isEmpty(urlTermIds.variant) &&
      (curatedEvidenceData?.curationRecords.variants?.length ?? 0) > 0 &&
      (curatedEvidenceData?.curationRecords.isSponsored || user.is_pro)
    ) {
      return true;
    }

    return false;
  }, [
    urlTermIds.variant,
    user,
    curatedEvidenceIsEnabled,
    curatedEvidenceData?.curationRecords,
  ]);

  const showExactMatch = useMemo(() => {
    let variantHasAlternateRepresentations = false;
    for (const [_, variantSuggestion] of Object.entries(
      urlTermSuggestions.variant
    )) {
      const altNotations = variantSuggestion.keys ?? [];
      if (altNotations.length > 1) variantHasAlternateRepresentations = true;
      if (altNotations.length === 1 && altNotations[0] !== variantSuggestion.id)
        variantHasAlternateRepresentations = true;
    }
    return variantHasAlternateRepresentations && user.is_pro;
  }, [urlTermSuggestions.variant, user]);

  const fetchFeaturedArticle = async (pmid: string) => {
    if (!isLoggedIn || !checkUrlHasTag(urlTermIds)) return;
    await triggerGetFeaturedArticleQuery(
      {
        pmid: pmid,
        urlTermIds: urlTermIds,
        urlBooleanParams: urlTermBooleans,
        urlCats: urlCats,
        urlSigTerms: urlSigTerms,
        options: {
          sortField: articleSortItem.id,
          sortAsc: articleSortItem.asc || false,
          searchText: articleSearchFilter,
          highlight: true,
        },
      },
      true
    )
      .then((res) => {
        dispatch(updateFeaturedArticleData(res.data as GetArticleResponse));

        // update prevSearchParamsRef so we can compare
        // search params accurately in the useEffect
        prevUrlTermIdsRef.current = urlTermIds;
        prevUrlTermBooleansRef.current = urlTermBooleans;
        prevUrlCatsRef.current = urlCats;
        prevUrlSigTermsRef.current = urlSigTerms;
        if (
          (res.data?.matchSummaries ?? []).length === 0 &&
          (res.data?.sentences ?? []).length === 0 &&
          (res.data?.condensedSentences ?? []).length === 0 &&
          res.status !== "rejected"
        ) {
          enqueueSnackbar(notValidPmidError, {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .catch((err) => handleError(err))
      .finally(() => setIsFetchingListAndArticleData(false));
  };

  const [triggerClinVarArticlesQuery] = useLazyGetClinVarArticlesQuery();

  const fetchClinVarArticles = async (variants: string[]) => {
    await triggerClinVarArticlesQuery(variants, true)
      .then(({ data }) => {
        dispatch(updateClinVarArticlesData(data as GetClinVarArticlesResponse));
      })
      .catch((err) => handleError(err));
  };

  const [triggerGetGeneEvidenceQuery] = useLazyGetGeneEvidenceQuery();

  useEffect(() => {
    void fetchArticlesList(0, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleSortItem, articleFilterItem, articleSearchFilter]);

  useEffect(() => {
    const currentSearchParamsPMID = searchParams.get(UrlParams.PMID);
    const haveParamsUpdated = checkHaveParamsUpdated();

    // if _only_ the PMID param changes, fetch new featured article data
    if (
      !isLoadingArticleViewer &&
      currentSearchParamsPMID &&
      !haveParamsUpdated
    ) {
      void fetchFeaturedArticle(currentSearchParamsPMID);
    } else if (haveParamsUpdated) {
      void fetchArticlesList(0, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, urlTermIds, urlTermBooleans, urlCats, urlSigTerms]);

  useEffect(() => {
    const variants = urlTermIds[SearchBarTerms.variant];
    if (variants.length > 0) {
      void fetchClinVarArticles(variants);
    }

    const genes = urlTermIds[SearchBarTerms.gene];
    if (genes.length > 0) {
      // fetch this to cache results
      void triggerGetGeneEvidenceQuery(genes, true).catch((err) =>
        handleError(err)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTermIds]);

  const handleArticleViewChange = () => {
    updateSearchParams(
      { [UrlParams.VIEW]: ArticlePageViews.ARTICLE_LIST },
      false,
      () => {
        prevUrlTermIdsRef.current = urlTermIds;
        prevUrlTermBooleansRef.current = urlTermBooleans;
        prevUrlCatsRef.current = urlCats;
        prevUrlSigTermsRef.current = urlSigTerms;
      }
    );
  };

  const handleArticleChange = async (pmid: string) => {
    updateSearchParams(
      {
        [UrlParams.PMID]: pmid,
        [UrlParams.VIEW]: ArticlePageViews.FEATURED_ARTICLE,
      },
      false,
      () => {
        prevUrlTermIdsRef.current = urlTermIds;
        prevUrlTermBooleansRef.current = urlTermBooleans;
        prevUrlCatsRef.current = urlCats;
        prevUrlSigTermsRef.current = urlSigTerms;
      }
    );

    await fetchFeaturedArticle(pmid);
  };

  return (
    <div className="page">
      <ArticlePage>
        <ArticlePage.LeftColumn
          showCuratedEvidence={showCuratedEvidence}
          showExactMatch={showExactMatch}
          GeneVariantCardComponent={<GeneVariantCard />}
          ExactMatchComponent={<ExactMatch />}
          ArticleListComponent={
            <ArticleList
              articleHeaderHeight={articleHeaderHeight}
              isFetchingArticleList={isLoadingOrInitArticleList}
              isEmpty={isEmptyArticlesList}
              onArticleChange={handleArticleChange}
              onLoadMore={handleLoadMore}
            >
              <ArticleListHeader
                allowArticlesCSVExport={allowArticlesCSVExport}
                showExactMatchLabel={showExactMatch}
                articleHeaderRef={articleHeaderRef}
              />
            </ArticleList>
          }
        />

        <ArticlePage.RightColumn
          showCuratedEvidence={showCuratedEvidence}
          onArticleViewChange={handleArticleViewChange}
          ArticleViewerComponent={
            <ArticleViewer
              isError={isErrorState}
              isLoadingArticleViewer={isLoadingArticleViewer}
              isEmptyArticleViewer={isEmptyArticleViewer}
            />
          }
          EvidenceViewerComponent={
            <EvidenceViewerContainer
              isError={isErrorState}
              onArticleChange={handleArticleChange}
            />
          }
          TreatmentTrialComponent={<TreatmentTrialContainer />}
          RelatedVariantsComponent={<RelatedVariantsContainer />}
        ></ArticlePage.RightColumn>
      </ArticlePage>
    </div>
  );
};

export default ArticlePageContainer;
