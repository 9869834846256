import React from "react";
import ArticleCard from "../../../../common/cards/article-card/ArticleCard";
import { EvidenceViewerArticleData } from "../../../../../network/reporter/types";
import { ArticleCardBody } from "../../../../common/cards/article-card/ArticleCardBody/ArticleCardBody";
import { ArticleCardBodyData } from "../../../../../network/articles";
import EvidenceViewerArticleCardFooter from "./EvidenceViewerArticleCardFooter";

type EvidenceViewerArticleCardProps = {
  articleData: EvidenceViewerArticleData;
  pmid: string;
  showClinVarBadge: boolean;
  onArticleChange: (pmid: string) => void;
};

const EvidenceViewerArticleCard = ({
  articleData,
  pmid,
  showClinVarBadge,
  onArticleChange,
}: EvidenceViewerArticleCardProps) => {
  const article: ArticleCardBodyData = {
    pmid: pmid,
    title: articleData.title,
    journal: articleData.journal,
    publication_date: articleData.date,
    authors: [articleData.primary_author],
  };

  return (
    <ArticleCard
      onCardClick={() => onArticleChange(pmid)}
      selected={false}
      pmid={pmid}
      testid="evidence-viewer-article-card"
    >
      <ArticleCard.Body>
        <ArticleCardBody
          article={article}
          showClinVarBadge={showClinVarBadge}
        />
      </ArticleCard.Body>
      <ArticleCard.Footer>
        <EvidenceViewerArticleCardFooter
          acmgRecords={articleData?.acmg_criteria}
          context={articleData?.context}
          data={articleData?.data}
        />
      </ArticleCard.Footer>
    </ArticleCard>
  );
};

export default EvidenceViewerArticleCard;
