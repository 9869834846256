import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { VariantResult } from "../../libs/models/variant-result";
import {
  ACMGCategories,
  ACMGAndUnclassifiedCategories,
  UnclassifiedCategories,
} from "../../libs/types/variant";
import { EvidenceViewerArticleDataByPMID } from "../../network/reporter/types";
import { ACMGCategoryDataType } from "../../components/article/evidence-viewer/EvidenceViewerACMGCriteria/ACMGCriteriaBody/types";

type EvidenceViewerArticleListData = {
  [key in ACMGAndUnclassifiedCategories]: EvidenceViewerArticleDataByPMID[];
};

type EvidenceViewerACMGCriteriaData = {
  [key in ACMGCategories]: ACMGCategoryDataType[];
};

// simple selectors
// build up simple selectors for use in complex selectos
const selectReporterCuratedData = (state: RootState) =>
  state.curatedEvidence?.reporterCuratedData;
const selectReporterEvidenceViewerData = (state: RootState) =>
  state.curatedEvidence?.reporterEvidenceViewerData;
const selectSelectedVariant = (state: RootState) =>
  state.curatedEvidence?.selectedVariant;

// complex selectors
export const selectCuratedVariantData = createSelector(
  selectReporterCuratedData,
  selectSelectedVariant,
  (curatedData, selectedVariant) => {
    if (curatedData && selectedVariant) {
      return curatedData?.curationRecords.variants.find(
        (v) =>
          VariantResult.variantCasing(v.id) ===
          VariantResult.variantCasing(selectedVariant.id)
      );
    }
    return undefined;
  }
);

export const selectEvidenceViewerArticleListData = createSelector(
  selectReporterEvidenceViewerData,
  (evidenceViewerData) => {
    const { json_record, pmid_curated_data } = evidenceViewerData?.record || {};
    if (!json_record || !pmid_curated_data) return undefined;

    const acmgKeys = Object.values(ACMGCategories);
    const initialAccumulator: EvidenceViewerArticleListData = {
      [ACMGCategories.POPULATION]: [],
      [ACMGCategories.COMPUTATIONAL]: [],
      [ACMGCategories.INTRINSIC]: [],
      [ACMGCategories.CLINICAL]: [],
      [ACMGCategories.FUNCTIONAL]: [],
      [UnclassifiedCategories.UNCLASSIFIED]: [],
    };

    return Object.entries(json_record).reduce<EvidenceViewerArticleListData>(
      (acc, [key, value]) => {
        let category: ACMGCategories | UnclassifiedCategories;

        if (key === "unweighted_case" && value.type === "clinical") {
          category = UnclassifiedCategories.UNCLASSIFIED;
        } else if (acmgKeys.includes(value.type as ACMGCategories)) {
          category = value.type as ACMGCategories;
        } else {
          category = UnclassifiedCategories.UNCLASSIFIED;
        }

        const newArticles = value.lit_items
          ? Object.values(value.lit_items)
              .map((item) => item.pmid)
              .filter((pmid) => !acc[category].some((obj) => pmid in obj))
              .map((pmid) => ({ [pmid]: pmid_curated_data[pmid] }))
          : [];

        return {
          ...acc,
          [category]: [...acc[category], ...newArticles],
        };
      },
      initialAccumulator
    );
  }
);

// Reorganizes the data into ACMG categories and places the acmg criteria
// and data in each category
// Ex:
// {
//   "population": [
//     {
//       acmg_call: "pp3",
//       short_desc: "this is my description",
//       lit_items: [],
//       strength: "strong",
//       ...
//     }
//   ],
//   ...
// }
export const selectEvidenceViewerACMGBodyData = createSelector(
  selectReporterEvidenceViewerData,
  (evidenceViewerData) => {
    const { json_record } = evidenceViewerData?.record || {};
    if (!json_record) return undefined;

    const acmgKeys = Object.values(ACMGCategories);
    const initialAccumulator: EvidenceViewerACMGCriteriaData = {
      [ACMGCategories.POPULATION]: [],
      [ACMGCategories.COMPUTATIONAL]: [],
      [ACMGCategories.INTRINSIC]: [],
      [ACMGCategories.CLINICAL]: [],
      [ACMGCategories.FUNCTIONAL]: [],
    };

    return Object.entries(json_record).reduce<EvidenceViewerACMGCriteriaData>(
      (acc, [key, value]) => {
        // unweighted cases are less useful for classification purposes
        // therefore exclude it from the Evidence Viewer ACMG Section
        if (!value || key === "unweighted_case") return acc;

        const category = acmgKeys.includes(value.type as ACMGCategories)
          ? (value.type as ACMGCategories)
          : "";

        if (!category) return acc;

        const newACMGItem = {
          ...value,
          acmg_call: key,
        };

        return {
          ...acc,
          [category]: [...acc[category], newACMGItem],
        };
      },
      initialAccumulator
    );
  }
);
