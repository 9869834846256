import { z } from "zod";

// TODO, need exhaustive list from GUAC/GCAT
export const CuratedDataObjectSchema = z.object({
  in_trans: z.string(),
  na_type: z.string(),
  probands: z.number(),
  segregation: z.string(),
  strength: z.string(),
  unrelated_proband: z.number(),
  zygosity: z.string(),
});

const SummarizedCountsSchema = z.object({
  weighted_probands: z.number(),
  meioses: z.number(),
  families_with_segregation: z.number(),
});

const ACMGLitItemEvidenceSchema = z.object({
  body: z.string(),
  new_data: z.boolean().nullable(),
});

const ACMGEvidenceTags = z.object({
  description: z.string(),
  name: z.string(),
});

const ACMGLitItemSchema = z.object({
  context: z.string().optional(),
  data: CuratedDataObjectSchema.partial().optional(),
  evidence: ACMGLitItemEvidenceSchema.or(z.string()), // may be able to remove once CEV released and data portal projects all re-released
  evidence_tags: z.array(ACMGEvidenceTags).optional(),
  evidence_text: z.string().optional(),
  evidence_type: z.string().optional(),
  pmid: z.string(),
});

export const ACMGCriteriaSchema = z.object({
  call: z.boolean(),
  caution: z.string().optional(), // may be able to remove once CEV released and data portal projects all re-released
  explanation: z.string().optional(), // may be able to remove once CEV released and data portal projects all re-released
  lit_call: z.boolean().nullish(),
  lit_items: z.array(ACMGLitItemSchema).optional(),
  non_lit_call: z.boolean().nullable().optional(),
  lit_nonlit_conflict: z.boolean().optional(),
  original_lit_item_count: z.number().optional(),
  short_desc: z.string(),
  strength: z.string().nullable().optional(), // may be able to remove once CEV released and data portal projects all re-released
  summarized_counts: SummarizedCountsSchema.partial().optional(),
  type: z.string().optional(), // may be able to remove once CEV released and data portal projects all re-released
});

export const CuratedPMIDSchema = z.object({
  acmg_criteria: z.array(z.string()),
  acmg_strength: z.array(z.string()).optional(),
  primary_author: z.string(),
  context: z.array(z.string()).optional(),
  data: z.array(CuratedDataObjectSchema.partial()),
  date: z.string(),
  evidence_tags: z.array(
    z.object({
      description: z.string(),
      name: z.string(),
    })
  ),
  evidence_text: z.array(z.string()).optional(),
  journal: z.string(),
  title: z.string(),
});

export const DiseaseTreatmentSchema = z.object({
  sponsored_therapies: z.array(
    z.object({
      description: z.string(),
      name: z.string(),
      url: z.string(),
      generic: z.string(),
    })
  ),
  treatment_resources: z.array(
    z.object({
      label: z.string(),
      url: z.string(),
    })
  ),
});

export const DiseaseResourceSchema = z.object({
  label: z.string(),
  url: z.string(),
  description: z.string(),
});

export const ReporterCuratedDataSchema = z.object({
  curationRecords: z.object({
    curatedFor: z.string(),
    gene: z.string(),
    isEnterprise: z.boolean(),
    isSponsored: z.boolean(),
    variants: z.array(
      z.object({
        acmg_call: z.string(),
        article_count: z.number(),
        gene: z.string(),
        id: z.string(),
        type: z.string(),
      })
    ),
  }),
});

export const ReporterClinvarDataSchema = z.object({
  clinVarRecords: z.array(
    z.object({
      citations: z.number(),
      description: z.string(),
      displayName: z.string(),
      fullName: z.string(),
      id: z.string(),
      interpretation: z.string(),
      lastEvaluated: z.string(),
      mostRecent: z.string(),
      reviewStars: z.number(),
      reviewStatus: z.string(),
      submissions: z.number(),
      submitterCalls: z.array(
        z.object({
          call: z.string(),
          submitter: z.string(),
        })
      ),
      url: z.string(),
    })
  ),
});

export const EvidenceViewerRecordsSchema = z
  .object({
    record: z
      .object({
        acmg_call: z.string(),
        data_portal_project_release_id: z.number(),
        data_portal_project_variant_id: z.number(),
        disease: z.object({
          clingen: z.any(),
          display_name: z.string(),
          resources: z.array(DiseaseResourceSchema).optional(),
          tags: z.any(),
          treatments: DiseaseTreatmentSchema.optional(),
        }),
        gene_symbol: z.string(),
        json_record: z.record(z.string(), ACMGCriteriaSchema),
        pmid_curated_data: z
          .record(z.string(), CuratedPMIDSchema)
          .optional()
          .nullable(),
        project_type: z.string(),
        review_date: z.string().optional(),
        status: z.string(),
        variant_id: z.string(),
      })
      .optional(),
    transcript: z.string().optional(),
  })
  .nullable();
