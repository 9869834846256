import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CurationRecordVariant } from "../../libs/types/articles";
import {
  GetEvidenceViewerRecordsResponse,
  GetReporterCuratedData,
} from "../../network/reporter/types";

export interface CuratedEvidenceState {
  reporterCuratedData?: GetReporterCuratedData;
  reporterEvidenceViewerData?: GetEvidenceViewerRecordsResponse;
  selectedVariant?: CurationRecordVariant;
}

export const initialCuratedEvidenceSliceState: CuratedEvidenceState = {
  reporterCuratedData: undefined,
  reporterEvidenceViewerData: undefined,
  selectedVariant: undefined,
};

export const curatedEvidenceSlice = createSlice({
  name: "curatedEvidence",
  initialState: initialCuratedEvidenceSliceState,
  reducers: {
    updateReporterCuratedData: (
      state: CuratedEvidenceState,
      action: PayloadAction<GetReporterCuratedData>
    ) => {
      state.reporterCuratedData = action.payload;
    },
    updateReporterEvidenceViewerData: (
      state: CuratedEvidenceState,
      action: PayloadAction<GetEvidenceViewerRecordsResponse>
    ) => {
      state.reporterEvidenceViewerData = action.payload;
    },
    updateSelectedVariant: (
      state: CuratedEvidenceState,
      action: PayloadAction<CurationRecordVariant>
    ) => {
      state.selectedVariant = action.payload;
    },
  },
});

export const {
  updateReporterCuratedData,
  updateReporterEvidenceViewerData,
  updateSelectedVariant,
} = curatedEvidenceSlice.actions;

export default curatedEvidenceSlice.reducer;
