// my-theme.ts
import { createTheme } from "@mui/material";
import React from "react";

export const primaryPalette = {
  gray: {
    variant_01: "#FFFFFF",
    variant_02: "#FAFAFA",
    variant_03: "#F2F2F2",
    variant_04: "#DAD9D8",
    variant_05: "#C2C2C0",
    variant_06: "#898988",
    variant_07: "#4A4A49",
    variant_08: "#262626",
    variant_09: "#000000",
    variant_10: "#F0F2F2",
    variant_11: "#C2BEB5",
    variant_12: "#FAF9F8",
    variant_13: "#F2F1F0",
  },
  grey: {
    // may want to consider moving 'gray' colors into this.  Using names as per figma
    neutral_grey_g9: "#000000",
    neutral_grey_g8: "#262626",
    neutral_grey_g7: "#4A4A49",
    neutral_grey_g6: "#898988",
    neutral_grey_g5: "#C2C2C0",
    neutral_grey_g4: "#DAD9D8",
    neutral_grey_g3: "#F2F2F2",
    neutral_grey_g2: "#FAFAFA",
    neutral_grey_g1: "#FFFFFF",
  },
  cnv: {
    variant_01: "#B1669A",
  },
  pathogenic: {
    variant_01: "#CA3D3D",
    variant_02: "#D77272",
    level_0: "rgba(189,77,89,1)", // based on current production
    level_1: "rgba(189,77,89,0.83)", // based on current production
    level_2: "rgba(189,77,89,0.66)", // based on current production
    level_3: "rgba(189,77,89,0.5)", // based on current production
  },
  likely_pathogenic: {
    variant_01: "#CA3D3D",
    variant_02: "#D77272",
  },
  benign: {
    variant_01: "#65B2DC",
    level_0: "rgba(0,56,105,1)", // based on current production
    level_1: "rgba(0,56,105,0.83)", // based on current production
    level_2: "rgba(0,56,105,0.66)", // based on current production
    level_3: "rgba(0,56,105,0.5)", // based on current production
  },
  likely_benign: {
    variant_01: "#65B2DC",
    level_0: "rgba(0,56,105,1)", // based on current production
    level_1: "rgba(0,56,105,0.83)", // based on current production
    level_2: "rgba(0,56,105,0.66)", // based on current production
    level_3: "rgba(0,56,105,0.5)", // based on current production
  },
  VUS: {
    variant_01: "#714A85",
  },
  vus: {
    variant_01: "#714A85",
  },
  uncurated: {
    variant_01: "#777777", // chose grey for now, will need to rethink
  },
  conflict: {
    variant_01: "#714A85",
  },
  teal: {
    neutral_teal_t6: "#104851",
    neutral_teal_t5: "#437179",
    neutral_teal_t4: "#678E95",
    neutral_teal_t3: "#84A4AA",
    neutral_teal_t2: "#E5E9EA",
    neutral_teal_t1: "#F2F7F7",
    variant_03: "#A3AFB0",
    variant_04: "#93A1A3",
    variant_05: "#6E8587",
    variant_07: "#66827D",
    variant_08: "rgba(34,71,74,0.3)",
  },
  blue: {
    brand_main: "#1F3781",
    variant_01: "#142864",
    variant_02: "#201A40", // indigo
    variant_03: "#0DBBEF", // cyan
    variant_04: "#83D0F5", // sky blue
  },
  gene: {
    variant_01: "#7C96BA",
  },
  variant: {
    variant_01: "#63AC80",
  },
  unii: {
    variant_01: "#AA9FB0",
  },
  hpo: {
    variant_01: "#BEA77B",
  },
  disease: {
    variant_01: "#B9876A",
  },
  keyword: {
    variant_01: "red",
  },
  brown: {
    neutral_brown_b6: "#4A4335",
    neutral_brown_b5: "#8F8A81",
    neutral_brown_b4: "#C2BEB5",
    neutral_brown_b3: "#DAD8D5",
    neutral_brown_b2: "#F2F1F0",
    neutral_brown_b1: "#FAF9F8",
    variant_01: "#FAF9F8", // replaced by neutral_brown_b1, may still need elsewhere TODO when have time to refactor usage
    variant_02: "#F2F1F0", // replaced by neutral_brown_b2, may still need elsewhere TODO when have time to refactor usage
    variant_03: "#DAD8D5",
  },
  focus: {
    variant_01: "#008DA6",
    variant_02: "#5FBCCC",
    variant_03: "#C9F3FB",
    variant_04: "#D7F9FF",
    variant_05: "#F0FDFF",
  },
  warning: {
    variant_01: "#CA3D3D",
  },
  dataVis: {
    green: "#5EA48B",
    c2: "#3E93A6",
    terracotta: "#C68854",
    keyword: "#A86896", // they didn't have a color name for this one so went with what it's for for now
    kiwi: "#B1AE61",
    strawberry: "#D77272",
    oldMM: "#DCE8F2",
    slate: "#D2DCD9",
    blue_light: "#A7DBE7",
  },
  dataVisLight: {
    green: "#B9DED1",
    c3: "#82C2C6",
    terracotta: "#F6C18F",
    keyword: "#DDB5D2",
    kiwi: "#CCCAA3",
    strawberry: "#DAB0B0",
    slate: "#D2DCD9",
    blue: "#A7DBE7",
  },
  gradients: {
    teal: {
      neutral_teal_t1: "linear-gradient(180deg, #F2F7F7 0%, #F3F8F9 100%)",
    },
    blue: {
      branding: "linear-gradient(220.64deg, #152F82 4.64%, #137ECA 80.35%)",
    },
  },
  dropShadow: {
    grey: "rgba(13, 63, 67, 0.30)",
    cyan: "rgba(13, 187, 239, 0.5)",
  },
};

export const variantColors = {
  variantBucket: {
    missense: "#08283F",
    synonymous: "#3E93A6",
    truncating: "#65B2DC",
    in_frame: "#5C81C9",
    splice: "#82C2C6",
    non_coding: "#1E39C3",
  },
};

export const textMatchColors = {
  gene: primaryPalette.dataVisLight.green,
  variant: primaryPalette.dataVisLight.c3,
  cnv: primaryPalette.dataVisLight.terracotta,
  keyword: primaryPalette.dataVisLight.keyword,
  phenotype: primaryPalette.dataVisLight.kiwi,
  therapy: primaryPalette.dataVisLight.strawberry,
};

export const boxShadowStyles = {
  default: `0px 16px 32px 0px ${primaryPalette.dropShadow.grey}`,
  geneCard: `0px 8px 8px 0px ${primaryPalette.dropShadow.grey}`,
  genePage: "0px 8px 16px 0px rgba(24, 100, 106, 0.06)",
  evidenceViewer: `0px 8px 8px 0px ${primaryPalette.dropShadow.grey}`,
  mastermindBanner: `0px -16px 16px 0px ${primaryPalette.dropShadow.grey}`,
  search: `2px 4px 4px 0px ${primaryPalette.dropShadow.cyan}, -2px -4px 4px 0px ${primaryPalette.dropShadow.cyan}`,
};

export const elementHeights = {
  navbar: "48px",
  fixedHeaderContent: "133px", // navbar + searchBar
  pageContent: `calc(100vh - 133px)`,
};

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 375,
      sm: 800,
      md: 1024,
      lg: 1200,
      xl: 1600,
    },
  },
  palette: {
    primary: {
      main: primaryPalette.teal.neutral_teal_t6,
      light: primaryPalette.teal.variant_05,
    },
    secondary: {
      main: primaryPalette.teal.neutral_teal_t2,
      contrastText: primaryPalette.gray.variant_07,
    },
    warning: {
      main: primaryPalette.warning.variant_01,
    },
  },
  typography: {
    fontFamily: ["Rubik Regular"].join(","),
    fontWeightRegular: 400,
    fontWeightBold: 600,
    fontWeightLight: 300,
    h1: {
      fontSize: "36px",
    },
    h2: {
      fontSize: "28px",
    },
    h3: {
      fontSize: "18px",
      fontWeight: 500,
    },
    h4: {
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: "0.56px",
      textTransform: "uppercase",
    },
    h5: {
      fontSize: "12px",
      fontWeight: 500,
      letterSpacing: "0.48px",
      textTransform: "uppercase",
    },
    h6: {
      fontSize: "12px",
      letterSpacing: "0.48px",
      textTransform: "uppercase",
    },
    text12: {
      fontSize: "12px",
    },
    text12Emphasis: {
      fontSize: "12px",
      fontWeight: 500,
    },
    text14: {
      fontSize: "14px",
    },
    text16: {
      fontSize: "16px",
    },
    text11: {
      fontSize: "11px",
    },
    articleHeadlineA1: {
      fontSize: "20px",
      fontWeight: 500,
    },
    homeH1: {
      fontFamily: "Rubik LightItalic",
      fontSize: "28px",
      textTransform: "uppercase",
    },
    homeH2: {
      fontFamily: "Rubik Light",
      fontSize: "20px",
      textTransform: "uppercase",
    },
    contrastText: {
      color: primaryPalette.gray.variant_07,
      fontSize: "0.75rem",
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
      variants: [
        {
          props: { variant: "searchBump" },
          style: () => ({
            padding: "10px 15px",
            border: "unset",
            "&:hover": {
              border: "unset",
            },
            boxShadow:
              "2px 4px 4px 0px rgba(1, 11, 10, 0.1), -2px -4px 4px 0px rgba(255, 252, 240, 0.1)",
            backgroundColor: primaryPalette.blue.variant_01,
            color: "white",
          }),
        },
        {
          props: { variant: "bump" },
          style: (style) => ({
            padding: "10px 15px",
            border: "unset",
            "&:hover": {
              border: "unset",
            },
            boxShadow:
              "2px 4px 4px 0px rgba(1, 11, 10, 0.10), -2px -4px 4px 0px rgba(255, 252, 240, 0.10)",
            backgroundColor: style.theme.palette.primary.main,
            color: "white",
          }),
        },
        {
          props: { variant: "bump", color: "secondary" },
          style: () => ({
            boxShadow:
              "2px 4px 4px 0px rgba(5, 46, 43, 0.08), -2px -4px 4px 0px rgba(255, 252, 240, 0.50)",
            backgroundColor: primaryPalette.teal.neutral_teal_t1,
            color: primaryPalette.teal.neutral_teal_t6,
            "&:hover": {
              border: "unset",
              backgroundColor: primaryPalette.teal.neutral_teal_t2,
            },
          }),
        },
        {
          props: { variant: "bumpDepressed", color: "secondary" },
          style: () => ({
            padding: "10px 15px",
            border: "unset",
            "&:hover": {
              border: "unset",
              backgroundColor: primaryPalette.teal.neutral_teal_t2,
            },
            boxShadow:
              "-2px -4px 4px 0px rgba(5, 46, 43, 0.08), 2px 4px 4px 0px rgba(255, 252, 240, 0.70)",
            backgroundColor: primaryPalette.teal.neutral_teal_t1,
            color: primaryPalette.teal.neutral_teal_t6,
          }),
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "2px",
          fontSize: "0.75rem",
          fontWeight: 400,
        },
      },
      variants: [
        {
          props: { variant: "filter" },
          style: () => ({
            backgroundColor: primaryPalette.teal.neutral_teal_t3,
            color: primaryPalette.grey.neutral_grey_g1,
            border: `1px solid ${primaryPalette.teal.neutral_teal_t3}`,
            transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            "&:hover": {
              backgroundColor: primaryPalette.teal.neutral_teal_t5,
              border: `1px solid ${primaryPalette.teal.neutral_teal_t5}`,
            },
            "&:focus": {
              border: `1px solid ${primaryPalette.teal.neutral_teal_t5}`,
            },
          }),
        },
        {
          props: { variant: "activeFilter" },
          style: () => ({
            backgroundColor: primaryPalette.teal.neutral_teal_t5,
            color: primaryPalette.grey.neutral_grey_g1,
            border: `1px solid ${primaryPalette.teal.neutral_teal_t5}`,
            transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1)",
            "&:hover": {
              backgroundColor: primaryPalette.teal.neutral_teal_t5,
              border: `1px solid ${primaryPalette.teal.neutral_teal_t5}`,
            },
            "&:focus": {
              border: `1px solid ${primaryPalette.teal.neutral_teal_t5}`,
            },
          }),
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Rubik Regular';
        src: url("/webfonts/Rubik-Regular.ttf") format("truetype")
      }

      @font-face {
        font-family: 'Rubik Black';
        src: url("/webfonts/Rubik-Black.ttf") format("truetype")
      }

      @font-face {
        font-family: 'Rubik BlackItalic';
        src: url("/webfonts/Rubik-BlackItalic.ttf") format("truetype")
      }

      @font-face {
        font-family: 'Rubik Bold';
        src: url("/webfonts/Rubik-Bold.ttf") format("truetype")
      }

      @font-face {
        font-family: 'Rubik BoldItalic';
        src: url("/webfonts/Rubik-BoldItalic.ttf") format("truetype")
      }

      @font-face {
        font-family: 'Rubik Italic';
        src: url("/webfonts/Rubik-Italic.ttf") format("truetype")
      }

      @font-face {
        font-family: 'Rubik Light';
        src: url("/webfonts/Rubik-Light.ttf") format("truetype")
      }

      @font-face {
        font-family: 'Rubik LightItalic';
        src: url("/webfonts/Rubik-LightItalic.ttf") format("truetype")
      }

      @font-face {
        font-family: 'Rubik Medium';
        src: url("/webfonts/Rubik-Medium.ttf") format("truetype")
      }

      @font-face {
        font-family: 'Rubik MediumItalic';
        src: url("/webfonts/Rubik-MediumItalic.ttf") format("truetype")
      }

      @font-face {
        font-family: 'Rubik SemiBold';
        src: url("/webfonts/Rubik-SemiBold.ttf") format("truetype")
      }

      @font-face {
        font-family: 'Rubik SemiBoldItalic';
        src: url("/webfonts/Rubik-SemiBoldItalic.ttf") format("truetype")
      }
            
      .gene {
        background-color: #B9DED1;
        color: #262626;
        font-weight: 700;
      }
      
      .variant {
        background-color: ${primaryPalette.dataVisLight.c3};
        color: #262626;
        font-weight: 700;
      }
      
      .unii {
        background-color: ${primaryPalette.dataVisLight.strawberry};
        color: #262626;
        font-weight: 700;
      }
      
      .hpo {
        background-color: ${primaryPalette.dataVisLight.kiwi};
        color: #262626;
        font-weight: 700;
      }
      
      .cnv {
        background-color: ${primaryPalette.dataVisLight.terracotta};
        color: #262626;
        font-weight: 700;
      }
      
      .keyword {
        background-color: ${primaryPalette.dataVisLight.keyword};
        color: #262626;
        font-weight: 700;
      }
      
      .no-highlight {
        background-color: transparent;
        color: ${primaryPalette.teal.neutral_teal_t6};
      }

      .page {
        background-color: ${primaryPalette.teal.neutral_teal_t2};
        padding: 10px;
        min-height: ${elementHeights.pageContent};
        margin-top: ${elementHeights.fixedHeaderContent};
        position: relative;
      }
    `,
    },
  },
  zIndex: {
    appBar: 1299,
  },
});

declare module "@mui/material/styles" {
  interface TypographyVariants {
    text12: React.CSSProperties;
    text12Emphasis: React.CSSProperties;
    text14: React.CSSProperties;
    text16: React.CSSProperties;
    text11: React.CSSProperties;
    homeH1: React.CSSProperties;
    homeH2: React.CSSProperties;
    articleHeadlineA1: React.CSSProperties;
    contrastText: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    text12?: React.CSSProperties;
    text12Emphasis?: React.CSSProperties;
    text14?: React.CSSProperties;
    text16?: React.CSSProperties;
    text11?: React.CSSProperties;
    homeH1?: React.CSSProperties;
    homeH2?: React.CSSProperties;
    articleHeadlineA1?: React.CSSProperties;
    contrastText?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    text12: true;
    text12Emphasis: true;
    text14: true;
    text16: true;
    text11: true;
    homeH1: true;
    homeH2: true;
    articleHeadlineA1: true;
    contrastText: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    bump: true;
    bumpDepressed: true;
    searchBump: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    filter: true;
    activeFilter: true;
  }
}
