import { Box, FormControlLabel, styled, Typography } from "@mui/material";
import { primaryPalette } from "../../../libs/styles/theme";

export const ArticleViewerText = styled(Typography)(() => ({
  color: primaryPalette.grey.neutral_grey_g7,
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
}));

type KeywordSelectorProps = {
  mmSelectorType: string;
};

const selectorTypeColor = (selectorType: string) => {
  switch (selectorType) {
    case "gene":
      return primaryPalette.dataVis.green;
    case "variant":
      return primaryPalette.dataVis.c2;
    case "cnv":
      return primaryPalette.dataVis.terracotta;
    case "keyword":
      return primaryPalette.dataVis.keyword;
    case "phenotype":
      return primaryPalette.dataVis.kiwi;
    case "therapy":
      return primaryPalette.dataVis.strawberry;
    default:
      return primaryPalette.dataVis.oldMM;
  }
};

export const KeywordUncheckedIcon = styled("span")(() => ({
  borderRadius: 3,
  height: 12,
  width: 12,
  background: "#FFFFFF",
}));

export const KeywordSelector = styled(Box, {
  shouldForwardProp: (prop) => !prop.toString().startsWith("mm"),
})((props: KeywordSelectorProps) => ({
  padding: "2px 8px 2px 4px",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: `${selectorTypeColor(props.mmSelectorType)}`,
  borderRadius: "2px",
  display: "flex",
  gap: "12px",
  height: "26px",
}));

export const KeywordFormControlLabel = styled(FormControlLabel)(() => ({
  color: "#FFFFFF",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  marginLeft: "-4px",
  marginRight: "0px",
}));
